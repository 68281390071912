<template>
  <div>
    <v-row
      class="alternate-rows"
      v-for="(crew, index) in crewMembers"
      :key="index">
      <v-col class="col-2">
        {{ crew.name }}<br />
        {{ crew.totalBonus | currency }}
      </v-col>
      <v-col class="col-2">
        <input
          @change="markCrewChecked($event, index)"
          type="checkbox"
          v-model="crew.checked">
      </v-col>
      <v-col class="col-2">
        Hours: {{ crew.totalHours }}<br />
        Overnights: {{ crew.overnights }}
      </v-col>
      <v-col v-for="(job, jindex) in crew.jobs" :key="jindex">
        {{ job.date | tinyDate }}<br />
        {{ job.hours }}<br />
        {{ job.reference }}<br />
        <span v-if="job.minimumBonus">
          <span class="text-red" v-if="job.bonus < job.minimumBonus">
            Minimum bonus ({{ job.minimumBonus | currency }})
          </span>
          <span v-else>
            {{ job.bonus | currency }}
          </span>
        </span>
        <span v-else>
          {{ job.bonus | currency }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'DrsData',
  props: [
    'week',
    'year',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    week() {
      this.getCrewMembers();
    },
  },
  data() {
    return {
      crewMembers: [],
    };
  },
  methods: {
    getCrewMembers() {
      axios.get(`/dailyReturnSheets/getWeeklyPay/${this.week}/${this.year}.json?token=${this.token}`)
        .then((response) => {
          this.$emit('weekDate', response.data.startDate);
          this.crewMembers = response.data.crewMembers;
        });
    },
    markCrewChecked(event, index) {
      const crewMember = this.crewMembers[index];
      if (event.originalTarget.checked === true) {
        axios.get(`/drsDataChecks/markDrsDataChecked/${this.week}/${this.year}/${crewMember.id}.json?token=${this.token}`);
      } else {
        axios.get(`/drsDataChecks/markDrsDataUnChecked/${this.week}/${this.year}/${crewMember.id}.json?token=${this.token}`);
      }
    },
  },
  mounted() {
    this.getCrewMembers();
  },
}
</script>
