<template>
  <div>
    <v-row>
      <v-col>NAME</v-col>
      <v-col></v-col>
      <v-col>MON</v-col>
      <v-col>TUE</v-col>
      <v-col>WED</v-col>
      <v-col>THU</v-col>
      <v-col>FRI</v-col>
      <v-col>SAT</v-col>
      <v-col>SUN</v-col>
      <v-col>TOTAL</v-col>
    </v-row>
    <v-row
      class="alternate-rows"
      v-for="(crew, index) in crewMembers"
      :key="index">
      <v-col>
        {{ crew.name }}
      </v-col>
      <v-col>
        HRS: {{ crew.totalHours }}<br />
        SUBSIS: {{ crew.overnights }}
      </v-col>
      <v-col>
        {{ crew.monday.hours}}<br />
        {{ crew.monday.subsistence | currency }}
      </v-col>
      <v-col>
        {{ crew.tuesday.hours}}<br />
        {{ crew.tuesday.subsistence | currency }}
      </v-col>
      <v-col>
        {{ crew.wednesday.hours}}<br />
        {{ crew.wednesday.subsistence | currency }}
      </v-col>
      <v-col>
        {{ crew.thursday.hours}}<br />
        {{ crew.thursday.subsistence | currency }}
      </v-col>
      <v-col>
        {{ crew.friday.hours}}<br />
        {{ crew.friday.subsistence | currency }}
      </v-col>
      <v-col>
        {{ crew.saturday.hours}}<br />
        {{ crew.saturday.subsistence | currency }}
      </v-col>
      <v-col>
        {{ crew.sunday.hours}}<br />
        {{ crew.sunday.subsistence | currency }}
      </v-col>
      <v-col>
        {{ crew.totalHours }}<br />
        {{ crew.totalSubsistence * 20 | currency }}
      </v-col>
    </v-row>
  </div>
</template>
  
<script>
import axios from '../../axios';

export default {
  name: 'UpdateHours',
  props: [
    'week',
    'year',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    week() {
      this.getCrewMembers();
    },
  },
  data() {
    return {
      crewMembers: [],
    };
  },
  methods: {
    getCrewMembers() {
      axios.get(`/dailyReturnSheets/getWeeklyHours/${this.week}/${this.year}.json?token=${this.token}`)
      .then((response) => {
        this.crewMembers = response.data.crewMembers;
      });
    },
  },
  mounted() {
    this.getCrewMembers();
  },
}
</script>
  