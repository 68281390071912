<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>
          Weekly hours and bonus report for Week {{ week }} {{ year }} starting {{ startDate | tinyDate }}
          <v-btn
            @click="showPicker = true"
            class="float-right"
            small>View calendar</v-btn>
          <v-btn
            @click="changeWeek(2)"
            class="float-right ml-2 mr-2"
            small>Next Week</v-btn>
          <v-btn
            @click="changeWeek(1)"
            class="float-right"
            small>Prev Week</v-btn>
        </h1>
      </v-col>
    </v-row>
    <v-row v-if="showPicker">
      <v-col>
        <v-date-picker v-model="weekDate"></v-date-picker>
      </v-col>
    </v-row>
    <v-tabs
      v-model="tab"
      grow
    >
      <v-tab>Drs Data</v-tab>
      <v-tab>Working out</v-tab>
      <v-tab>Weekly wages</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item class="pt-5">
        <app-drs-data @weekDate="setDate" :week="week" :year="year"></app-drs-data>
      </v-tab-item>
      <v-tab-item class="pt-5">
        <app-update-hours :week="week" :year="year"></app-update-hours>
      </v-tab-item>
      <v-tab-item class="pt-5">
        <app-weekly-wages :startDate="startDate" :week="week" :year="year"></app-weekly-wages>
      </v-tab-item>
    </v-tabs-items>
    
  </v-container>
</template>

<script>
import drsData from '@/components/PaymentReport/DrsData.vue';
import updateHours from '@/components/PaymentReport/UpdateHours.vue';
import weeklyWages from '@/components/PaymentReport/WeeklyWages.vue';

export default {
  name: 'ReportsWeeklyPay',
  components: {
    appDrsData: drsData,
    appUpdateHours: updateHours,
    appWeeklyWages: weeklyWages,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      tab: '',
      showPicker: false,
      week: 0,
      year: 0,
      weekDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      startDate: null,
    };
  },
  watch: {
    weekDate() {
      this.showPicker = false;
      this.updateWeek();
    },
  },
  methods: {
    setDate(value) {
      this.startDate = value;
    },
    changeWeek(direction) {
      if (direction === 1) {
        if (this.week > 1) {
          this.week = parseInt(this.week) - 1;
        } else {
          this.week = 52;
          this.year = parseInt(this.year) - 1;
        }
      } else {
        if (this.week < 52) {
          this.week = parseInt(this.week) + 1;
        } else {
          this.week = 1;
          this.year = parseInt(this.year) + 1;
        }
      }
    },
    updateWeek() {
      const currentDate = new Date(this.weekDate);
      const startDate = new Date(currentDate.getFullYear(), 0, 1);
      const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
      this.week = Math.ceil(days / 7);
      this.year = currentDate.getFullYear();
    },
    
  },
  mounted() {
    this.updateWeek();
  },
};
</script>
