<template>
  <div>
    <v-row v-if="reportNotBuilt" class="mb-2 mt-2">
      <v-col class="col-7">
        <p>
        Please ensure all DRS data is entered before clicking Build Report.
        Any subsequent DRS data entered will be moved to next week for payroll purposes.
        You can rebuild the report if required by clicking on Rebuild Week Data at the bottom
        of the page but this will lose any data you have manually entered.
      </p>
      </v-col>
      <v-col class="col-2">
        <v-btn
          block
          @click="getPayData()"
        >
          Build Report
        </v-btn>
      </v-col>
    </v-row>
    <v-simple-table v-if="!reportNotBuilt" fixed-header height="400px" dense>
      <thead>
        <tr class="grid-lines">
          <th>NAME</th>
          <th>RATES</th>
          <th>HOURS</th>
          <th v-if="showDays">MON</th>
          <th v-if="showDays">TUE</th>
          <th v-if="showDays">WED</th>
          <th v-if="showDays">THU</th>
          <th v-if="showDays">FRI</th>
          <th v-if="showDays">SAT</th>
          <th v-if="showDays">SUN</th>
          <th>BANK HOLS</th>
          <th>NORMAL HOLS</th>
          <th>TOTALS</th>
          <th>BONUS</th>
          <th>SUBSIS</th>
          <th>DEDUCT</th>
          <th>EXP</th>
          <th>NOTES</th>
          <th>Total Hrs as per DRS</th>
          <th>Total after Discrepancies</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="alternate-rows grid-lines input-container"
          v-for="(line, index) in payData.pay_lines"
          :key="index">
          <td>
            {{ line.crew_member.name }}
          </td>
          <td class="td-tooltip" :data-tooltip="line.crew_member.name">
              <div class="currency-input">
                <input type="text" v-model="line.normal_rate" /><br />
              </div>
              <div class="currency-input">
                <input type="text" v-model="line.overtime_rate" /><br />
              </div>
          </td>
          <td class="td-tooltip" :data-tooltip="line.crew_member.name">
            NORM<br />
            O/T
          </td>
          <td v-if="showDays" class="td-tooltip" :data-tooltip="line.crew_member.name">
            <span v-if="line.mon_absent" class="absent-indicator">{{ line.mon_absent }}</span>
          </td>
          <td v-if="showDays" class="td-tooltip" :data-tooltip="line.crew_member.name">
            <span v-if="line.tue_absent" class="absent-indicator">{{ line.tue_absent }}</span>
          </td>
          <td v-if="showDays" class="td-tooltip" :data-tooltip="line.crew_member.name">
            <span v-if="line.wed_absent" class="absent-indicator">{{ line.wed_absent }}</span>
          </td>
          <td v-if="showDays" class="td-tooltip" :data-tooltip="line.crew_member.name">
            <span v-if="line.thu_absent" class="absent-indicator">{{ line.thu_absent }}</span>
          </td>
          <td v-if="showDays" class="td-tooltip" :data-tooltip="line.crew_member.name">
            <span v-if="line.fri_absent" class="absent-indicator">{{ line.fri_absent }}</span>
          </td>
          <td v-if="showDays" class="td-tooltip" :data-tooltip="line.crew_member.name">
          </td>
          <td v-if="showDays" class="td-tooltip" :data-tooltip="line.crew_member.name">
          </td>
          <td class="td-tooltip" :data-tooltip="line.crew_member.name">
            <input type="number" min="0" step="1" v-model.number="line.bank_hol_top" />
          </td>
          <td class="td-tooltip" :data-tooltip="line.crew_member.name">
            <input type="number" min="0" step="1" v-model.number="line.norm_hol_top" />
          </td>
          <td class="td-tooltip" :data-tooltip="line.crew_member.name">
            <input type="text" disabled v-model="line.totals_top" /><br />
            <input type="text" disabled v-model="line.totals_bottom" />
          </td>
          <td class="td-tooltip" :data-tooltip="line.crew_member.name">
            <div class="currency-input">
              <input type="text" v-model="line.bonus" />
            </div>
          </td>
          <td class="td-tooltip" :data-tooltip="line.crew_member.name">
            <div class="currency-input">
              <input type="text" v-model="line.subsis" />
            </div>
          </td>
          <td class="td-tooltip" :data-tooltip="line.crew_member.name">
            <input type="text" v-model="line.deduct" />
          </td>
          <td class="td-tooltip" :data-tooltip="line.crew_member.name">
            <input type="text" v-model="line.exp" />
          </td>
          <td class="td-tooltip" :data-tooltip="line.crew_member.name">
            <input type="text" v-model="line.notes" />
          </td>
          <td class="td-tooltip" :data-tooltip="line.crew_member.name">
            <input type="text" v-model="line.total_hrs_as_per_drs" />
          </td>
          <td class="td-tooltip" :data-tooltip="line.crew_member.name">
            <input type="text" v-model="line.total_after_discrepancies" />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-row v-if="!reportNotBuilt" class="pt-5 pb-10">
      <v-col>
        <v-btn
          v-if="showDays"
          @click="showDays = false">
          Hide days
        </v-btn>
        <v-btn
          v-else
          @click="showDays = true">
          Show days
        </v-btn>
        &nbsp;
        <v-btn @click="save">Save Changes</v-btn>
        &nbsp;
        <v-btn @click="exportSpreadsheet">Export to Excel</v-btn>
        &nbsp;
        <v-btn @click="clearWeek">Rebuild Week Data</v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showMessage"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            text
            @click="showMessage = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'WeeklyWages',
  props: [
    'week',
    'year',
    'startDate',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    week() {
      this.checkReportIsNotBuilt();
    },
    payData: {
      deep: true,
      handler() {
        for ( let i = 0; this.payData.pay_lines.length > i; i += 1) {
          const normalHoursTotal = (
            parseFloat(this.payData.pay_lines[i].mon_top)
            + parseFloat(this.payData.pay_lines[i].tue_top)
            + parseFloat(this.payData.pay_lines[i].wed_top)
            + parseFloat(this.payData.pay_lines[i].thu_top)
            + parseFloat(this.payData.pay_lines[i].fri_top)
            + parseFloat(this.payData.pay_lines[i].sat_top)
            + parseFloat(this.payData.pay_lines[i].sun_top)
          );
          this.payData.pay_lines[i].totals_top = normalHoursTotal;
          const otHoursTotal = (
            parseFloat(this.payData.pay_lines[i].mon_bottom)
            + parseFloat(this.payData.pay_lines[i].tue_bottom)
            + parseFloat(this.payData.pay_lines[i].wed_bottom)
            + parseFloat(this.payData.pay_lines[i].thu_bottom)
            + parseFloat(this.payData.pay_lines[i].fri_bottom)
            + parseFloat(this.payData.pay_lines[i].sat_bottom)
            + parseFloat(this.payData.pay_lines[i].sun_bottom)
          );
          this.payData.pay_lines[i].totals_bottom = otHoursTotal;
          this.payData.pay_lines[i].total_after_discrepancies = (
            parseFloat(normalHoursTotal) +
            parseFloat(otHoursTotal)
          );
        }
      },
    },
  },
  data() {
    return {
      payData: {
        pay_lines: [],
      },
      message: '',
      showMessage: false,
      showDays: false,
      reportNotBuilt: false,
    };
  },
  methods: {
    checkReportIsNotBuilt() {
      axios.get(`/payWeeks/checkReportIsNotBuilt/${this.week}/${this.year}.json?token=${this.token}`)
        .then((response) => {
          this.reportNotBuilt = response.data.response;
          if (response.data.response === false) {
            this.getPayData();
          }
        });
    },
    clearWeek() {
      axios.get(`/payWeeks/clearWeek/${this.payData.id}.json?token=${this.token}`)
      .then(() => {
        this.getPayData();
      });
    },
    save() {
      axios.post(`/payLines/update.json?token=${this.token}`, this.payData)
      .then(() => {
        this.message = 'All lines saved';
        this.showMessage = true;
      });
    },
    getPayData() {
      axios.get(`/payWeeks/getWeeklyFinal/${this.week}/${this.year}.json?token=${this.token}`)
      .then((response) => {
        this.reportNotBuilt = false;
        this.payData = response.data.payData;
      });
    },
    exportSpreadsheet() {
      const postData = {};
      postData.lines = this.payData.pay_lines;
      postData.showDays = this.showDays;
      axios.post(`/spreadsheets/weeklyFinal.json?token=${this.token}`, postData, {responseType:'blob'})
        .then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `Wages Week ${this.week} WB ${this.startDate}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },
  },
  mounted() {
    this.checkReportIsNotBuilt();
  },
}
</script>

<style lang="scss">
.absent-indicator {
  display: inline-block;
  font-size: 12px;
  padding: 2px;
  background-color: #FBCECE;
}
</style>
